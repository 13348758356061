.chat-sidebar-wrapper {
    display: flex;
    height: 100%;
    position: relative;
    width: 60px;
    transition: width 0.3s ease;
    z-index: 10;
}

.chat-sidebar-wrapper.panel-open {
    width: 420px; 
}

.chat-sidebar-container {
    width: 60px;
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #eaeaea;
    flex-shrink: 0;
    box-shadow: 4px 0 12px rgba(0, 0, 0, 0.08);
    position: relative;
    z-index: 2;
}

.chat-sidebar-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    gap: 16px;
}

.chat-sidebar-icon-btn {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
    color: #595959;
    color: var(--dark-grey-clr, #595959);
}

.chat-sidebar-icon-btn:hover {
    background-color: var(--lighter-grey-2-clr);
}

.chat-sidebar-icon-btn.active-icon {
    background-color: var(--light-grey-clr);
    color: var(--main-clr);
}

.chat-sidebar-icon-btn svg {
    width: 20px;
    height: 20px;
}

/* Sidebar panel container styles */
.sidebar-panel-container {
    position: absolute;
    left: 60px;
    top: 0;
    height: 100%;
    width: 0;
    background-color: white;
    overflow: hidden;
    transition: width 0.3s ease;
    box-shadow: 4px 0 12px rgba(0, 0, 0, 0.08);
    border-right: 1px solid #eaeaea;
}

.panel-open .sidebar-panel-container {
    width: 360px;
}

/* Generic sidebar panel styling */
.sidebar-panel {
    width: 360px;
    height: 100%;
    padding: 16px;
    overflow-y: auto;
}

.panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 0 16px;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 15px;
}

.panel-header h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: #131313;
    color: var(--text-clr, #131313);
}

.panel-close-btn {
    background: none;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #595959;
    color: var(--dark-grey-clr, #595959);
}

.panel-close-btn:hover {
    background-color: #f3f3f3;
    background-color: var(--lighter-grey-2-clr, #f3f3f3);
}

.panel-close-btn svg {
    width: 16px;
    height: 16px;
}

.panel-content {
    padding: 0px 16px 16px 16px;
    overflow-y: auto;
    height: calc(100% - 60px);
}


@media only screen and (max-width: 768px) {
    .chat-sidebar-wrapper.panel-open {
        width: 300px;
    }
    
    .panel-open .sidebar-panel-container {
        width: 240px;
    }
    
    .sidebar-panel {
        width: 240px;
    }
}

@media only screen and (max-width: 600px) {
    .chat-sidebar-wrapper {
        flex-direction: column;
        height: auto;
        width: 100%;
    }
    
    .chat-sidebar-wrapper.panel-open {
        width: 100%;
    }
    
    .chat-sidebar-container {
        width: 100%;
        height: 60px;
        flex-direction: row;
        justify-content: center;
        border-right: none;
        border-top: 1px solid #eaeaea;
        box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.08);
    }
    
    .chat-sidebar-icons {
        flex-direction: row;
        padding-top: 0;
        align-items: center;
        justify-content: center;
        gap: 36px; 
        height: 100%;
    }
    
    .sidebar-panel-container {
        position: fixed;
        left: 0;
        top: 60px;
        width: 100%;
        height: 0;
        transition: height 0.3s ease;
        z-index: 100;
    }
    
    .panel-open .sidebar-panel-container {
        width: 100%;
        height: calc(100% - 60px);
    }
    
    .sidebar-panel {
        width: 100%;
        height: 100%;
    }
}