
.modal-container[data-v-44ef82fd] {
    pointer-events: none;
}
[data-v-44ef82fd]:global(.modal-container .cancel-button),[data-v-44ef82fd]:global(.modal-container .delete-button) {
    pointer-events: auto;
}
.flex-btns-actions[data-v-44ef82fd]{
    display: flex;
    gap: 8px;
    font-family: Assistant;
    align-items: center;
    font-size: 18px;
}
button[data-v-44ef82fd]{
    background-color: transparent;
    outline: none;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cancel-button[data-v-44ef82fd]
{
    border-color: var(--main-clr);
    border: 1px solid var(--main-clr);
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
}
.delete-button[data-v-44ef82fd]
{
    background-color: var(--main-clr);
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
}
.flex-btns-actions button[data-v-44ef82fd]{
    cursor: pointer;
}
