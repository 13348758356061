.document-preview {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    background-color: var(--white-clr, #ffffff);
    border-radius: 5px;
    padding: 4px 8px;
    width: 150px;
    max-width: 200px;
    max-height: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.file-icon {
    margin-right: 8px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.file-icon svg {
    width: 16px;
    height: 16px;
}

.file-name {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1 1;
    color: #131313;
    color: var(--text-clr, #131313);
    margin-right: 8px;
}

.document-close-button {
    margin-left: auto;
}