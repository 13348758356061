.table-container[data-v-117dc33b] {
  overflow: auto;
  max-height: calc(100vh - 300px);
  width: 100%;
  -webkit-overflow-scrolling: touch;
}
table[data-v-117dc33b] {
  border: 0;
  width: 100%;
  min-width: -webkit-max-content;
  min-width: max-content;
  table-layout: auto;
  font-size: 16px;
  border-collapse: collapse;
  font-family: Assistant;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  filter: none;
  transform: translateZ(0);
}
tbody tr[data-v-117dc33b] {
  background-color: var(--white-clr);
  border: 10px solid var(--chat-background-clr);
  width: 100%;
  display: table-row;
}
.checkbox[data-v-117dc33b] {
  display: none;
}
thead[data-v-117dc33b] {
  width: 100%;
}
thead th[data-v-117dc33b] {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: var(--chat-background-clr);
  padding: 13px 10px;
}
tr img[data-v-117dc33b] {
  width: 18px;
  height: 18px;
}
td[data-v-117dc33b],
th[data-v-117dc33b] {
  min-width: 120px;
  max-width: 300px;
  transform: translateZ(0);
}
td[data-v-117dc33b] {
  border-right: none;
  border-left: none;
  font-size: 18px;
  padding: 3px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
td[data-v-117dc33b]:first-child,
th[data-v-117dc33b]:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}
.vector-modal-btn[data-v-117dc33b] {
  background-color: transparent;
  border: 0;
  outline: 0;
}
.flex-vector-methods[data-v-117dc33b] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
}
.flex-vector-methods button[data-v-117dc33b] {
  font-size: 18px;
  display: flex;
  gap: 16px;
}
.icon-name-container[data-v-117dc33b] {
  display: flex;
  gap: 8px;
  font-family: Assistant;
  align-items: center;
  font-size: 18px;
  white-space: nowrap;
}
.marked-row[data-v-117dc33b] {
  background-color: var(--main-undertone-clr);
}
textarea[data-v-117dc33b] {
  height: 41px;
  border-radius: 10px;
  border: 0;
  background: var(--lighter-grey-clr);
  padding: 10px;
  resize: none;
  width: 200px;
}
textarea[data-v-117dc33b]:focus {
  outline: none !important;
  box-shadow: 0 0 6px var(--main-clr);
}
.search-container[data-v-117dc33b] {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 14px;
}
.select-box-tool-box[data-v-117dc33b],
.agents-select[data-v-117dc33b] {
  width: 160px;
  height: 32px;
  flex-shrink: 0;
  direction: ltr;
  font-family: Assistant;
  background: #e3e3e3;
  background: var(--Light-Mode-Light-Grey, #e3e3e3);
  border-radius: 5px;
  outline: none;
  margin-left: 3px;
  border:0;
  padding: 10px;
  font-size: "16px";
  font-style: "normal";
  font-weight: 400;
  line-height: "20px";
  color: "var(--Light-Mode-Black, #131313)";
}
.search-field-container[data-v-117dc33b] {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  padding: 8px;
}
.search-field[data-v-117dc33b] {
  width: 100%;
}
.search-field input[data-v-117dc33b] {
  height: 36px;
  background-color: #fff;
  font-size: 14px;
  padding: 0 8px;
}
.search-field fieldset[data-v-117dc33b] {
  border: 1px solid #e0e0e0;
}
.search-field:hover fieldset[data-v-117dc33b] {
  border-color: #b0b0b0;
}
.search-field.Mui-focused fieldset[data-v-117dc33b] {
  border-color: var(--main-clr);
}
.flows-list[data-v-117dc33b] {
  max-height: 250px;
}
.flow-item[data-v-117dc33b] {
  font-size: 14px;
  padding: 8px 16px;
}
.flow-item[data-v-117dc33b]:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.MuiSelect-root .MuiPaper-root[data-v-117dc33b] {
  max-height: 300px !important;
  overflow: hidden !important;
}
.flows-container[data-v-117dc33b] {
  overflow: hidden;
}
@media screen and (max-width: 768px) {
.table-container[data-v-117dc33b] {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
td[data-v-117dc33b],
  th[data-v-117dc33b] {
    min-width: 100px;
}
}
