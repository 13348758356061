/* ============= Upload Status Window ============= */
.upload-status-window {
    position: fixed;
    right: 0;
    width: 23%;
    min-width: 20%;
    height: 50px;
    background-color: var(--white-clr);
    border-top: 1px solid #ccc;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    padding: 10px 4px 10px 10px;
    max-width: 450px;
    min-width: 250px;
    bottom: 80px;
  }
  .upload-status-window.expanded {
    bottom: 350px;
  }
  .upload-status-window .header {
    display: grid;
    grid-template-columns: 40px 1fr 30px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    font-weight: 600;
    cursor: grab;
    -webkit-user-select: none;
            user-select: none;
  }
  .header:active {
    cursor: grabbing; /* Change cursor when dragging */
  }
  .header span {
    -webkit-user-select: none;
            user-select: none;
    margin-bottom: 4px;
  }
  
  .buttons {
    display: flex;
    flex-direction: row;
  }
  
  .option-btn {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: auto;
  }
  
  /* ============= File List ============= */
  .file-list {
    position: fixed;
    left: 0;
    width: 100%;
    min-width: 70%;
    overflow-y: auto;
    max-height: 280px;
    height: 280px;
    background-color: var(--white-clr);
    border-top: 1px solid #ccc;
    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .file-item {
    margin: 0.4em 0;
    padding: 0 8px 0 4px;
    max-width: 450px;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    font-family: Assistant;
    align-items: center;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    min-height: 42px;
    justify-items: left;
  }
  
  /* ============= Process Item ============= */
  .process-item {
    display: grid;
    grid-template-columns: 30px 1fr;
    width: 100%;
    align-items: center;
    grid-gap: 18px;
    gap: 18px;
  }
  .process-item svg {
    width: 16px;
    height: 16px;
    margin: auto;
  }
  .process-details {
    display: flex;
    flex-direction: column;
    justify-items: left;
    align-items: center;
    width: 100%;
  }
  .process-details label {
    font-size: 1rem;
    margin: auto auto auto 0;
  }
  
  /* 
     Unify the progress bar styling for both .process-details and .process-item-container
     so that it will stretch fully 
  */
  .process-details progress,
  .process-item-container progress {
    width: 100%;
    height: 10px;
    margin: 0.3em 0;
    border-radius: 5px;
    background-color: #E3E3E3;
    border: 1px solid #E3E3E3;
  }
  
  /* Normal fill color */
  .process-details progress::-webkit-progress-value,
  .process-item-container progress::-webkit-progress-value {
    background-color: #f96ddf;
    border: 1px solid #f96ddf;
    -webkit-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;
  }
  
  /* Fail color */
  .process-item.fail .process-details progress::-webkit-progress-value {
    background-color: #939393;
    border: 1px solid #939393;
  }
  
  /* The progress bar background */
  .process-details progress::-webkit-progress-bar,
  .process-item-container progress::-webkit-progress-bar {
    background-color: #E3E3E3;
    border: 1px solid #E3E3E3;
  }
  
  .process-item .process-details .process-details-header {
    display: grid;
    width: 100%;
  }
  .process-item.fail .process-details .process-details-header {
    grid-template-columns: 1fr 60px;
  }
  .process-item .process-details .process-details-header {
    grid-template-columns: 1fr 40px;
  }
  .process-details .process-details-header .process-controls {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
  }
  .process-details .process-details-header .process-controls button {
    background: none;
    display: flex;
    border: none;
    font-size: 1.5em;
    cursor: auto;
    padding: 0;
  }
  .process-details .process-details-header .process-controls button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .process-details .process-details-header .process-controls button:disabled svg {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .process-item.fail .process-details .process-details-header .process-controls {
    grid-template-columns: repeat(3, 1fr);
  }
  
  /* ============= Process Container ============= */
  .process-container {
    width: 100%;
    height: 0;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    padding: 5px 0 0 0;
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    overflow: hidden;
  }
  .process-container.show {
    height: auto;
    opacity: 1;
  }
  /* We only need one display rule for .process-container .process-item */
  .process-container .process-item {
    display: grid;
  }
  .process-container .process-item .error {
    width: 100%;
    text-align: left;
    font-size: 14px;
  }
  
  /* ============= File Name Container ============= */
  .file-name-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    max-width: 100%;
    width: 100%;
    font-family: Assistant;
    align-items: center;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .file-name-container button {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: auto;
    padding: 0 3px;
  }
  .file-name-container .timestamp {
    border-right: 2px solid #ccc;
    padding-right: 4px;
  }
  .file-name-container .collapse-btn {
    transition: transform 0.5s ease-in-out;
    transform: rotate(-180deg);
  }
  .file-name-container .collapse-btn.show {
    transform: rotate(0deg);
  }
  .file-name-container .collapse-btn svg {
    width: 14px;
    height: 14px;
    margin: auto;
  }
  .file-name-container span {
    font-size: 14px;
    margin-left: 4%;
  }
  .file-name-container img {
    width: 18px;
    height: 18px;
    margin: auto 0;
  }
  .file-name-container svg {
    width: 18px;
    height: 18px;
    margin: auto 0 auto auto;
  }
  
  .file-details {
    display: flex;
    flex-direction: row;
    justify-items: left;
    align-items: center;
    width: 100%;
    overflow: hidden;
  }
  
  .processes-summary {
    display: flex;
    justify-content: left;
    text-align: left;
    width: 100%;
    margin: auto auto auto 50px;
    font-size: 14px;
  }
  
  .file-details span {
    position: relative;
  }
  .file-details button {
    border: none;
    background: none;
  }
  
  .icon-name-container span {
    font-size: 14px;
    margin-left: 4%;
  }
  .icon-name-container img {
    width: 14px;
    height: 14px;
    margin: auto 0;
  }
  
  /* ============= Loading / Status Icons ============= */
  .loading-spinner {
    width: 15px;
    height: 15px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  .status-check {
    color: green;
  }
  .status-icon {
    cursor: auto;
  }
  .status-error {
    color: red;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* ============= Process Item Container (for the new progress bar) ============= */
  .process-item-container {
    display: flex;
    flex-direction: column;
    align-items: stretch; /* ensures the progress bar can be full width */
    width: 100%;
  }
  
  /* Info below the bar (percentage + event type) */
  .progress-info {
    margin-top: 4px;
    font-size: 0.9rem;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 6px;
  }
  
  .progress-percentage {
    font-weight: bold;
    margin-right: 4px;
  }
  
  .progress-type {
    text-transform: capitalize; /* e.g., "Pending", "Prepare", etc. */
  }
  