.url-file-toggler[data-v-443867ea] {
    width: 100%;
    background-color: var(--main-undertone-clr);
    border-radius: 10px;
    padding: 7px 10px;
    height: 42px;
    align-items: center;
}
.url-file-toggler button[data-v-443867ea] {
    width: 50%;
    border-radius: 9px;
    border: 0;
    font-size: 16px;
    cursor: pointer;
    height: 100%;
    background-color: transparent;
}
.active-toggle[data-v-443867ea] {
    background-color: var(--main-clr) !important;
}
.prompt-purpose-title[data-v-443867ea] {
    width: 100%;
}
.input-file-container[data-v-443867ea] {
    color: var(--text-clr);
    border-radius: 12px;
    border: dashed 1px var(--main-clr);
    font-family: 'openSans-light';
    height: 100px;
    width: 100%;
    z-index: 2;
    position: relative;
    /* cursor: pointer; */
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 14px;
}
.input-file-container.hover[data-v-443867ea] {
    border: dashed 4px var(--main-clr);
    cursor: copy;
}
.file-picker-input[data-v-443867ea] {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    z-index: 3;
    height: 100%;
    width: 100%;
}
.vectors-upper[data-v-443867ea] {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.vectors-methods-description[data-v-443867ea] {
    display: flex;
    gap: 5px;
}
.vectors-methods-description span[data-v-443867ea] {
    opacity: 0.5;
}
.select-box-tool-box[data-v-443867ea] {
    direction: ltr;
  
    /* width: 150px; */
    font-family: Assistant;
    background-color: var(--lighter-grey-clr);
    height: 30px;
    border-radius: 8px;
    /* border: solid 1px #193552; */
    outline: none;
    font-size: 12px;
    color: var(--text-clr);
    width: 50%;
  
    /* font-family: 'openSans-extraBold'; */
}
.lower-part-vectors[data-v-443867ea] {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}
.lower-part-vectors button[data-v-443867ea] {
    flex-basis: 31%;
    border: 0;
    max-width: 33%;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in;
    font-size: 12px;
    background-color: var(--lighter-grey-clr);
    padding: 1.5em 1em;
}
.active-vector[data-v-443867ea] {
    color: black;
    background-color: var(--main-undertone-clr) !important;
}
.modal-content[data-v-443867ea] {
    min-width: 450px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
.chunks-options-container[data-v-443867ea] {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.single-chunk-container input[data-v-443867ea] {
    border-radius: 8px;
    width: 120px;
    height: 32px;
    background: rgba(227, 227, 227, 0.5);
    border: 0;
    padding-left: 10px;
    font-size: 14px;
    color: var(--text-clr);
}
.single-chunk-container[data-v-443867ea] {
    display: flex;
    align-items: center;
    gap: 20px;
}
.submit-btn-document[data-v-443867ea] {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 8px;
    border: 0;
    margin-top: 10px;
    padding: 6px 10px;
    background-color: var(--main-clr);
    color: var(--text-clr);
    cursor: pointer;
}
.submit-btn-document[data-v-443867ea]:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  
    background-color: var(--lighter-grey-clr);
}
.file-return-flex[data-v-443867ea] {
    display: flex;
    gap: 10px;
}
.file-return-flex h2[data-v-443867ea] {
    font-size: 18px;
}
.file-return-flex button[data-v-443867ea] {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 0;
}
.flex-files[data-v-443867ea] {
    /* align-items: center; */
    display: flex;
    max-width: 450px;
    width: 100%;
    max-height: 150px;
    /* height:150px; */
    border-radius: 10px;
    /* gap:5px; */
    flex-direction: column;
    /* align-items: center; */
    flex-wrap: nowrap;
    /* width: 100%; */
    /* background-color: var(--lighter-grey-clr); */
    overflow: scroll;
    /* scrollbar-width: thin;  */
    overflow-y: auto;
    overflow-x: hidden;
}
.file[data-v-443867ea] {
    /* background-color: var(--lighter-grey-clr); */
    /* border-radius: 10px; */
    padding: 0.5em 1em;
    background-color: var(--lighter-grey-clr);
    /* width: 100%; */
    max-width: 450px;
    width: 450px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
button[data-v-443867ea] {
    background-color: transparent;
    outline: none;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon-name-container[data-v-443867ea] {
    display: block;
    gap: 8px;
    /* width: 55%; */
    max-width: 450px;
    font-family: Assistant;
    align-items: center;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
img[data-v-443867ea] {
    width: 18px;
    height: 18px;
}
.upload-settings[data-v-443867ea] {
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.upload-setting[data-v-443867ea] {
    display: flex;
    align-items: center;
    gap: 5px;
    align-self: baseline;
}
.embedding-dropdown[data-v-443867ea] {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 100%;
}
.select-box-tool-box[data-v-443867ea] {
    direction: ltr;
  
    width: 150px;
    font-family: Assistant;
    background-color: var(--lighter-grey-clr);
    height: 30px;
    border-radius: 8px;
    outline: none;
    font-size: 14px;
    color: var(--text-clr);
    width: 50%;
}
.existing-files[data-v-443867ea] {
    display: flex;
    color: #FF0000;
    font-size: 16px;
}