/* Select.css */
.custom-select-container {
    position: relative;
    width: 200px;
  }
  
  .custom-select-header {
    width: 100%;
    padding: 8px 32px 8px 12px;
    background-color: #f1f1f1;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    -webkit-user-select: none;
            user-select: none;
  }
  
  .custom-select-header.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .custom-select-header .select-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .custom-select-header .select-value.placeholder {
    color: #666;
  }
  
  .select-arrow {
    position: absolute;
    right: 12px;
    width: 8px;
    height: 8px;
    border: solid #666;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: transform 0.2s ease;
  }
  
  .custom-select-header.open .select-arrow {
    transform: rotate(-135deg);
    top: 45%;
  }
  
  .select-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 4px;
    background: white;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    overflow-y: auto;
    z-index: 1000;
  }
  
  .select-option {
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;
    color: #333;
    transition: background-color 0.2s ease;
  }
  
  .select-option:hover {
    background-color: #f5f5f5;
  }
  
  .select-option.selected {
    background-color: #f1f1f1;
    font-weight: 500;
  }
  
  /* Custom scrollbar for the dropdown */
  .select-dropdown::-webkit-scrollbar {
    width: 8px;
  }
  
  .select-dropdown::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  .select-dropdown::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }
  
  .select-dropdown::-webkit-scrollbar-thumb:hover {
    background: #bbb;
  }