.new-chat-button {
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: white;
    border: 1px solid var(--main-clr);
    border-radius: 10px;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    cursor: pointer;
    transition: all 0.2s ease;
}

.new-chat-button:hover {
    background-color: var(--lighest-grey-clr);
}

.new-chat-icon {
    font-size: 16px;
    color: var(--main-clr);
}
