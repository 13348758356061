.download-container {
    position: relative;
    display: inline-block;
}

.download-dropdown {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    border: 1px solid var(--secondary-clr);
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;
    min-width: 120px;
    transition: opacity 0.2s, visibility 0.2s;
}

.download-container:hover .download-dropdown {
    visibility: visible;
    opacity: 1;
}

.dropdown-item {
    padding: 8px 16px;
    cursor: pointer;
    white-space: nowrap;
    font-size: 14px;
    color: var(--text-clr);
}

.dropdown-item:hover {
    border-radius: 5px;
    background-color: var(--lighter-grey-clr);
}
