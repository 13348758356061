.image-preview {
    padding: 0;
    max-width: 100px;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.image-container {
    width: 100px;
    height: 60px;
    overflow: hidden;
    cursor: pointer;
}

.image-container:hover {
    opacity: 0.9;
}

.preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-close-button {
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: rgba(255, 255, 255, 0.8);
    width: 20px;
    height: 20px;
    padding: 2px;
}

.image-close-button:hover {
    background-color: rgba(255, 255, 255, 1);
}

.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
}

.image-modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.full-size-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 90vh;
}

.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0;
}
.modal-close-button svg {
    width: 25px;
    height: 25px;
}
