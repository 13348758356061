.tooltip-title .section-label[data-v-1966956f] {
  color: #000;
  font-family: 'Assistant', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.bold-label[data-v-1966956f] {
  font-weight: 600;
}
.section-label[data-v-1966956f] {
  margin-bottom: 4px;
}
.tooltip-subtitle[data-v-1966956f],
.section-value[data-v-1966956f],
.pricing-item[data-v-1966956f] {
  color: #000;
  font-family: 'Assistant', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4;
  margin: 0;
}
.tooltip-separator[data-v-1966956f] {
  border: none;
  border-top: 1px solid #ddd;
  margin: 12px 0;
}
.grid-section[data-v-1966956f] {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0; /* Remove any default padding */
  gap: 16px; /* Use gap for consistent spacing between items */
}
.grid-item[data-v-1966956f] {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 1;
  word-wrap: break-word;
  word-break: break-word;
}
.vertical-separator[data-v-1966956f] {
  width: 1px;
  background-color: #ddd;
  align-self: stretch;
  margin: 0;
}
.pricing-section[data-v-1966956f] {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
}
.title-and-icons[data-v-1966956f] {
  /* line-height: center; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 60px;
}
.icons[data-v-1966956f] {
  /* width: 50px; */
  display: flex;
  flex-direction: row;
  align-items: center;
}
