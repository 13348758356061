.chat-canvas-container {
    margin-top: 16px;
    border-top: 1px solid #e0e0e0;
}

.chat-canvas-tab {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin-top: 12px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    -webkit-user-select: none;
            user-select: none;
}

.chat-canvas-tab:hover {
    background-color: #e8e8e8;
}

.chat-canvas-icon {
    margin-right: 8px;
    font-size: 16px;
}

.chat-canvas-title {
    flex-grow: 1;
    font-weight: 500;
    color: #333;
}

.chat-canvas-toggle {
    font-size: 12px;
    color: #666;
}

.chat-canvas-content {
    margin-top: 12px;
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
    max-height: 400px;
    overflow-y: auto;
    animation: slideDown 0.3s ease-out forwards;
}

.chat-canvas-content.closing {
    animation: slideUp 0.3s ease-in forwards;
}

@keyframes slideDown {
    from {
        max-height: 0;
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        max-height: 400px;
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideUp {
    from {
        max-height: 400px;
        opacity: 1;
        transform: translateY(0);
    }
    to {
        max-height: 0;
        opacity: 0;
        transform: translateY(-10px);
    }
}

/* Only keep layout-specific styles */
.chat-canvas-editor {
    pointer-events: none; /* Prevent editing */
}