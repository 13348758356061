.settings-item  {
    width: 100%;
}

.settings-item :global(.MuiOutlinedInput-root) {
    border-radius: 6px;
    font-size: 14px;
}

.settings-item :global(.MuiOutlinedInput-input) {
    padding: 10px 12px;
}

.settings-item  {
    border-color: #eaeaea;
}