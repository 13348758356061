.history-seperator[data-v-9de01dd1] {
  height: 2px;
  width: 90%;
  margin: 0 auto;
  background-color: rgb(229, 234, 239);
}

/* Search */
.search-container[data-v-9de01dd1]{
  border-bottom: 1px solid var(--lighter-grey-clr);
}
.search-container p[data-v-9de01dd1]{
  font-size: 16px;
  background-color: rgba(241, 241, 255, 0.95);
}
.search-input[data-v-9de01dd1] {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  border-radius: 8px;
  outline: 0;
  border: 0;
  width: 280px;
  padding: 8px 12px;
  padding-left:40px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  gap: var(--4-px, 4px);
  background: url('@/assets/search-icon-longer-handle.svg') no-repeat left 10px center;
}
.search-container .history-categories[data-v-9de01dd1]{
  padding-left: 20px;
  margin-right: 10px;
}
.search-input-container[data-v-9de01dd1]{
  position: relative;
  width: 280px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
  border-radius: 8px;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  margin-right: auto;
}


/* Suggestions Dropdown */
.suggestions-dropdown[data-v-9de01dd1]{
  position: absolute;
  z-index: 1000;  
  width: 100%;     
  background: white; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.10);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.suggestions-dropdown img[data-v-9de01dd1] {
  width: 16px;
  margin-right:5px;
  margin-left:5px;
  justify-content: center;
  align-items: center;
}
.suggestion-item[data-v-9de01dd1] {
  font-size: 14px;
  display: flex;
  justify-content: space-between; 
  padding-right:20px;
}
.suggestion-item[data-v-9de01dd1]:hover {
  background-color: var(--lighter-grey-clr);
}
.suggestion-text[data-v-9de01dd1] {
  flex-grow: 1; /* Pushes the pin icon to the right */
}

/* chats */
.history-chats[data-v-9de01dd1] {
  display: flex;
  gap: 10px;
  position: relative;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  height: calc(100vh - 250px);
}
.history-chats .header[data-v-9de01dd1] {
  display: flex;
}
.history-chats .header .logo-container[data-v-9de01dd1] {
  width: 87px;
  height: 87px;
  padding-top: 20px;
}

/* Icons Menu */

/* pinned chat */
.chat-pinned-icon-wrapper[data-v-9de01dd1] {
  display: flex;
  flex-direction: column;
}
.chat-pinned-icon[data-v-9de01dd1]{
  width: 16px;
  height: 15px;
}

/* chat's logs */
.history-chats .answer-container[data-v-9de01dd1] {
  width: 50%;
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left; 
  text-align: left;
  font-style: normal;
  font-size: 14px;
}
.chat-container[data-v-9de01dd1] {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  padding-left: 35px;
}
.history-chats .answer-container p[data-v-9de01dd1] {
  font-weight: 600;
}
.history-chats .answer[data-v-9de01dd1] {
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  white-space: normal;
  color: var(--text-clr);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}
.history-chats .history-ques-date[data-v-9de01dd1] {
  width: 25%;
  direction: rtl;
  text-align: left;
  font-size: 14px;
  font-family: Assistant;
}
.history-chats .search-examples-input[data-v-9de01dd1] {
  direction: rtl;
  color: var(--faded-blue-clr);
  font-size: 16px;
  padding: 5px 15px;
  margin-bottom: 30px;
  width: 214px;
  height: 35px;
  border-radius: 6px;
  background-color: var(--white-clr);
  font-family: Assistant;
  border: 0;
}
.history-chats .search-examples-input[data-v-9de01dd1]::placeholder {
  color: var(--faded-blue-clr);
}
.history-chats .single-chat[data-v-9de01dd1] {
  width: 100%;
  cursor: pointer;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  height: auto;
  padding: 10px;
  margin: 0 auto;
  position: relative;
  background-color: transparent;
  transition: background-color 0.2s ease-in;
}



/* .history-chats .active-log {
  background-color: rgba(250, 250, 250, 1) !important;
}
.history-chats .active-session {
  background-color: var(--lighter-grey-clr);
}
.history-chats .single-chat .back-home {
  direction: rtl;
  text-decoration: underline;
  cursor: pointer;
} */
/* .history-chats .tag-in-history {
  direction: rtl;
  margin-top: 6px;
  font-size: 14px;
}
.history-chats .pagination-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 50%;
  margin-bottom: 10px;
}
.history-chats .pagination-container span {
  cursor: pointer;
} */
.user-categories[data-v-9de01dd1] {
  width: 100%;
}
.see-more-button p[data-v-9de01dd1] {
  font-size: 11px;
  color: var(--text-clr);
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
  background:transparent;
  white-space: nowrap;
  margin: 0;
  width: 40px;
}
.see-more-button[data-v-9de01dd1] {
  background-color: transparent;
  border: 0;
}
.edit-user-categories[data-v-9de01dd1] {
  padding:0px;
  margin:none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
.edit-user-categories img[data-v-9de01dd1]{
  width: 10px;
  height: 10px;
  margin-right: 0;
}
.history-categories[data-v-9de01dd1]{
  padding: 10px 0;
  padding-right: 10px;
  display:flex;
  gap:5px;
  flex-direction: row;
}
.single-chat.active-log .chat-container[data-v-9de01dd1] {
  background-color: rgb(252, 252, 252);
}
.question-version-container.active-question[data-v-9de01dd1] {
  background-color: rgb(252, 252, 252);
}
.category-chip[data-v-9de01dd1]{
  font-size: 12px;
  font-weight: 400;
  border-radius: 8px;
  color:var(--text-clr);
  background: var(--main-undertone-clr);
  width: auto;
  height: 24px;
  border: none; 
  border: 1px solid var(--light-grey-clr);
  margin:0;
}
#activeCategory[data-v-9de01dd1] {
  border: 2px solid var(--main-clr) !important;
}
.category-chip[data-v-9de01dd1] :hover{
  background: var(--text-clrs);
}
.category-chip img[data-v-9de01dd1]{
  width:6px;
  margin-left: 2px;
  align-items: center;
}
.category-chip img[data-v-9de01dd1] :hover{
  width:8px;
  margin-left: 2px;
}
.icons-container[data-v-9de01dd1] {
  display: flex;
  gap: 8px; 
  align-items: center;
}

/* Three-dot button container */
.dots-container[data-v-9de01dd1] {
  position: relative; 
  cursor: pointer;
}
.dots-icon[data-v-9de01dd1] {
  width: 12px;
  height: 12px;
}

/* pop-up menu styling */
.dots-menu[data-v-9de01dd1] {
  position: absolute;
  right: 150%; 
  top: -170%;
  min-width: 150px;
  width: 200%;
  background: #FFF;
  background: var(--Light-Mode-White, #FFF);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 10px 0;
  padding-bottom: 0;
  z-index: 999;
}

/* Each button item in the menu */
.dots-menu button[data-v-9de01dd1] {
  background: none;
  border: none;
  width: 100%;
  padding: 6px 12px;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.dots-menu img[data-v-9de01dd1] {
  padding-right: 6px;
  width: 20px
}

/* hover style for the menu buttons */
.dots-menu button[data-v-9de01dd1]:hover {
  background: var(--lighter-grey-clr);
}

/* 
  Category item with submenu
*/
.menu-item-category[data-v-9de01dd1] {
  position: relative;
  display: flex; 
  align-items: center;
  padding: 0px 12px;
  padding-bottom: 10px;
  cursor: pointer;
}
.menu-item-category p[data-v-9de01dd1]{
  font-size: 14px;
  color: var(--text-clr);
}
.submenu-arrow[data-v-9de01dd1] {
  padding-top: 10px;
  margin-left: auto;
  width: 12px;
}

/* Submenu that appears on hover */
.category-submenu[data-v-9de01dd1] {
  display: relative;
  position: absolute;
  top: 100%;
  left: 0;
  width:100%;
  min-width: 140px;
  background: #FFF;
  background: var(--Light-Mode-White, #FFF);
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  padding: 8px;
  z-index: 10000;
}

/* Sub-menu items */
.category-submenu label[data-v-9de01dd1] {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  font-size: 14px;
}
/* adds space between checkbox and text */
.category-submenu label input[type="checkbox"][data-v-9de01dd1] {
  margin-right: 8px;
}
.category-submenu button[data-v-9de01dd1]{
  cursor: pointer;
  padding: 5px;
}
.category-submenu button img[data-v-9de01dd1]{
  width: 18px;
  height: 18px;
}
.add-new-category input[data-v-9de01dd1]{
  width: 80%;
}

/* Left icon spacing */
.user-category-edit-icon[data-v-9de01dd1] {
  width: 40px;
  height: 16px;
  margin-right: 8px; /* space between the icon and text */
}
.arrow-icon[data-v-9de01dd1] {
  width: 18px;
  height: 18px;
  transition: transform 0.3s ease;
}
.flipped[data-v-9de01dd1] {
  transform: scaleY(-1);
}
.category-submenu input[type="checkbox"][data-v-9de01dd1] {
  accent-color: #FFF;
  accent-color: var(--Light-Mode-White, #FFF);
}
.question-version-container[data-v-9de01dd1]{
  display: flex;
  flex-direction: column;
  border-color: black;
  padding-bottom: 5px;
  padding-top: 5px;
}
.question-version[data-v-9de01dd1]{
  display: flex;
  align-items: left;
  width: 100%;
  align-items: left;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  gap: 5px;
  padding: 5px;
}
.question-version-container[data-v-9de01dd1]:hover{
  background-color: var(--lighest-grey-clr);
}
.chat-container[data-v-9de01dd1]:active {
  background-color: var(--lighest-grey-clr);
}
.question-version-file[data-v-9de01dd1]{
  display: flex;
  gap: 5px;
  padding: 5px;
  margin-left: 40px;
  margin-right: 100px;
  margin-bottom: 5px;
  border-radius: 5px;
  font-size: 12px;
  background-color: var(--white-clr);
}
.question-version span[data-v-9de01dd1]{
  color: var(--light-grey-clr);
  font-weight: bold;
}
.add-category-icon[data-v-9de01dd1] {
  margin-right: 5px;
  width:50px;
  height:50px;
}
@media only screen and (max-width: 600px) {
.history-headline[data-v-9de01dd1] {
    padding-left: 10px !important;
}
.history-chats[data-v-9de01dd1] {
    width: 315px;
}
.question[data-v-9de01dd1] {
    font-size: 14px;
}
.answer[data-v-9de01dd1] {
    font-size: 14px;
}
}
.restore-chat-flex[data-v-9de01dd1] {
  display: flex;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  background-color: var(--white-clr);
  border-top: 1px solid var(--lighter-grey-clr);
  bottom: 0%;
  width: 100%;
  height: 80px;
  z-index: 4;
}
.restore-btn[data-v-9de01dd1] {
  background-color: transparent;
  font-weight: 500;
  font-size: 14px;
  padding: 0.5em 1em;
  cursor: pointer;
  border-radius: 8px;
  background-color: var(--main-clr);
  border: none;
  color: var(--text-clr-clr);
  font-family: Assistant;
}
.date-container[data-v-9de01dd1]{
  display: flex;
}
.exact-date[data-v-9de01dd1] {
  font-weight: 500;
  font-family: Assistant;
  justify-content: left;
  font-size: 12px;
  margin-top: 5px;
  /* padding-left: 20px;
  padding-right: 30px; */
  color: var(--text-clr);
}
.exact-date span[data-v-9de01dd1]{
  color: var(--main-clr);
  font-weight: bold;
}
.single-chat[data-v-9de01dd1]::before {
  content: '';
  width: 92%;
  display: block;
  position: absolute;
  border-bottom: 1px solid var(--lighter-grey-clr);
  bottom: 0%;
}
.files-counter[data-v-9de01dd1] {
  padding: 0.03em 0.2em;
  display: block;
  position: absolute;
  z-index: 1;
  /* top: 50 %; */
  transform: translate(-50%, -70%);
  border-radius: 5px 5px 0px 5px;
  color: var(--text-clr);

  /* width: 17px;
  height: 14px; */
  text-align: center;
  background-color: var(--main-clr);
  /* Text/Hint */
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.file-type-img[data-v-9de01dd1] {
  height: 24px;
  width: 24px;
  margin-top:5px;
}
.history-subheadline[data-v-9de01dd1] {
  font-size: 16px;
  margin-top: 10px;
  padding-left: 20px;
  font-family: Assistant;
  text-align: left;
  align-items: flex-start;
  font-weight: 400;
  margin-bottom: 10px;
  color: var(--text-clr);
}
.history-headline[data-v-9de01dd1] {
  font-size: 16px;
  margin-top: 10px;
  padding-left: 30px;
  font-family: Assistant;
  text-align: left;
  align-items: flex-start;
  font-weight: 500;
  color: var(--text-clr);
}
