.flex-bots[data-v-74010d96] {
  display: flex;
  max-width: 1000px;
  width: 50%;
  gap: 5px;
  flex-direction: column;
  flex-wrap: wrap;
}
.bot[data-v-74010d96] {
  background-color: var(--lighter-grey-clr);
  border-radius: 10px;
  padding: 0.5em 1em;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.icon-name-container[data-v-74010d96] {
  display: flex;
  gap: 8px;
  font-family: Assistant;
  align-items: center;
  font-size: 18px;
}
button[data-v-74010d96] {
  background-color: transparent;
  outline: none;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-btns-actions[data-v-74010d96] {
  display: flex;
}
.flex-btns-actions button[data-v-74010d96] {
  cursor: pointer;
}
.search-box-container[data-v-74010d96] {
  position: relative;
}
.search-dropdown[data-v-74010d96] {
  padding-top: 10px;
  position: absolute;
  left: 0;
  width: 100%;
  background: #fff;
  border: var(--lighter-grey-clr) 1px solid;
  z-index: 999;
  margin-bottom: 10px;
}
.search-dropdown-inner[data-v-74010d96] {
  max-height: 300px;
  overflow-y: auto;
}
.search-dropdown-item[data-v-74010d96]{
  display: flex;
  padding: 8px;
  gap:7px;
  border-bottom: 1px solid var(--lighter-grey-clr);
}
.search-dropdown-item[data-v-74010d96]:hover{
  background-color: var(--main-undertone-clr);
}
.search-dropdown-item #users-list[data-v-74010d96]:hover{
  background-color: rgb(255, 255, 255);
  width:40px;
  height:40px;
  border-radius: 50px;
}
.search-input[data-v-74010d96] {
  font-weight: 400;
  font-size:16px ;
  color: var(--text-clr);
  border-radius: 8px;
  border-radius: var(--8px, 8px);
  outline: 0;
  border: 0;
  background-color: color-mix(in srgb, var(--Light-Mode-Light-Grey) 25%, transparent);
  width: 440px;
  height: 42px;
  padding: 8px 12px;
  padding: var(--8px, 8px) var(--12px, 12px);
  gap: 4px;
  gap: var(--4-px, 4px);
}
.dropdown-user-name[data-v-74010d96]{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.dropdown-user-email[data-v-74010d96]{
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.no-results[data-v-74010d96]{
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-clr);
  margin: 10px 0;
}
.users-with-access[data-v-74010d96] {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  position: relative;
}
.users-with-access[data-v-74010d96]::after {
  content: "";
  display: block;
  width: 440px; 
  border-bottom: 1px solid var(--lighter-grey-clr);
  padding-left: 5px;
  margin-top: 10px;
}
.users-with-access-item[data-v-74010d96]{
  padding: 0 10px;
  display: flex;
  width: 440px;
  gap:10px;
  align-items: center;
  justify-content: left;
  border-bottom: 1px solid var(--lighter-grey-clr);
  transition: background-color 0.2s ease-in-out;
}
.users-with-access-item.selected[data-v-74010d96] {
  background-color: var(--main-undertone-clr);
}
.name-shortcut[data-v-74010d96]{
  background-color: var(--lighter-grey-clr);
  color: var(--text-clr);
  width:40px;
  height:40px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  margin: 10px 0;
  display: flex;
  align-items: center;     
  justify-content: center;
}
.access-full-name[data-v-74010d96]{
  font-size: 16px;
  font-weight: 400;
  color: var(--text-clr);
}
.access-email[data-v-74010d96]{
  font-size: 14px;
  font-weight: 300;
  color: var(--text-clr);
}
.user-details[data-v-74010d96]{
  display: flex;
  flex-direction: column;
}
.left-side[data-v-74010d96] {
  display: flex;
  align-items: center;
  gap: 10px; 
  flex-grow: 1;
}
.select-box-tool-box[data-v-74010d96] {
  direction: ltr;
  width: 150px;
  background-color: var(--lighter-grey-clr);
  height: 30px;
  border-radius: 8px;
  outline: none;
  font-size: 14px;
  font-weight: 400;
}
.select-box-tool-box-last[data-v-74010d96] {
  direction: ltr;
  width: 150px;
  background-color: var(--lighter-grey-clr);
  height: 30px;
  border-radius: 8px;
  outline: none;
  font-size: 12px;
  color: var(--faded-blue-clr);
  width: 100%;
  font-weight: 400;
}
.users-acess-container[data-v-74010d96]{
  margin-left: 60px;
}
.user-access-list[data-v-74010d96] {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 500px;
}
.remove-access-btn[data-v-74010d96] {
  padding-left: 10px;
  transition: background-color 0.2s ease-in-out;
  background: transparent;
  border: none;
  cursor: pointer;
}
.submit-btn-document[data-v-74010d96]{
  bottom: 20px; 
  width: -webkit-fit-content; 
  width: -moz-fit-content; 
  width: fit-content;
  border-radius: 8px;
  border: 0;
  margin-top: 10px;
  padding: 6px 10px;
  background-color: var(--main-clr);
  color: var(--text-clr);
  cursor: pointer;
}
.submit-btn-document[data-v-74010d96]:disabled{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  opacity: 0.5;
  cursor: inherit !important;
  border-radius: 8px;
  border: 0;
  margin-top: 10px;
  padding: 6px 10px;
  background-color: var(--main-clr);
  color: var(--text-clr);
  cursor: pointer;
}