.user-avatar {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Assistant, sans-serif;
    font-size: 16px;
    font-weight: 700;
    background-color: var(--main-undertone-clr);
    color: var(--text-clr);
    text-align: center;
    line-height: 1;
    overflow: hidden;
    box-sizing: border-box;
    text-indent: -125%;
}
