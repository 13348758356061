.chat-layout {
    display: flex;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.chat-content {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding: 20px 0 20px 20px;
    height: 100%;
    overflow: hidden;
    transition: margin-left 0.3s ease, width 0.3s ease;
    width: calc(100% - 60px);
    position: relative;
    z-index: 2; 
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
}

.chat-layout.sidebar-panel-open .chat-content {
    margin-left: 360px;
    width: calc(100% - 360px);
}

.chat-messages-container {
    flex-grow: 1;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.chat-input-section {
    width: 100%;
    z-index: 10;
    padding-bottom: 70px;
}

@media (max-height: 700px) {
    .chat-input-section {
        padding-top: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .chat-layout.sidebar-panel-open .chat-content {
        margin-left: 300px;
        width: calc(100% - 300px);
    }
}

@media only screen and (max-width: 600px) {
    .chat-layout {
        flex-direction: column;
    }
    
    .chat-content {
        width: 100%;
        margin-top: 60px; /* Space for the top bar on mobile */
    }
    
    .chat-layout.sidebar-panel-open .chat-content {
        margin-left: 0;
        width: 100%;
        opacity: 0.3; /* Dim the content when panel is open on mobile */
        pointer-events: none; /* Prevent interaction with dimmed content */
    }
}