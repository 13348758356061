.attached-files-preview {
    display: flex;
    flex-wrap: nowrap; 
    gap: 8px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 8px 0;
    scrollbar-width: thin;
    -ms-overflow-style: none;
    scrollbar-color: #d2d2d2 transparent;
    scrollbar-color: var(--light-grey-clr, #d2d2d2) transparent;
}

.file-preview, .image-preview, .document-preview {
    flex: 0 0 auto;
}