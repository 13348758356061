tbody tr[data-v-35b36a73]{
    background-color: var(--white-clr);
    border: 10px solid var(--chat-background-clr);
}
.checkbox[data-v-35b36a73]{
    display: none;
}
tr[data-v-35b36a73]{ width: 100%;}
thead[data-v-35b36a73]{
    width: 100px;
    /* background-color: var(--chat-background-clr); */
}
.processing-modal-btn[data-v-35b36a73]{
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0 auto 0 0;
    cursor: pointer;
}
.processing-modal-btn[data-v-35b36a73]:disabled{
    background-color: transparent;
    border: 0;
    outline: 0;
}
.processing-list[data-v-35b36a73] {
    margin: 5px 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.processing-list button[data-v-35b36a73]{
    font-size: 18px;
    display: flex;
    gap: 16px;
}
.processing-item[data-v-35b36a73] {
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr) 20px;
}
.processing-item svg[data-v-35b36a73] {
    width: 14px;
    height: 14px;
    margin: auto;
}
.processing-item.disabled .processing-modal-btn svg[data-v-35b36a73], .processing-item.aborted .processing-modal-btn svg[data-v-35b36a73] {
    opacity: 0.5;
    cursor: not-allowed;
}
.processing-item button[data-v-35b36a73]{
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
}
.processing-status[data-v-35b36a73] {
    display: flex;
    align-items: center;
    gap: 8px;
}
.processing-status svg[data-v-35b36a73] {
    width: 20px;
    height: 20px;
}
thead th[data-v-35b36a73] {
    position: -webkit-sticky;
    position: sticky;
    /* font-family: 'Courier New', Courier, monospace; */
    top: 0;  /* Adjust this if you have any fixed element at the top of the page */
    z-index: 2;
    background-color: var(--chat-background-clr);
    padding: 13px 10px;
}
tr img[data-v-35b36a73]{
    width:18px;
    height: 18px;
}
td[data-v-35b36a73], th[data-v-35b36a73]{
    min-width: 120px;
}
table[data-v-35b36a73]{
    border: 0;
    width: 100%;
    table-layout: fixed;
    font-size: 16px;
    border-collapse: collapse;
    font-family: Assistant;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.table-container[data-v-35b36a73]{
    /* width: 1300px; */
    /* padding-right: 20px;
    padding-left: 20px; */
    overflow: auto;
    max-height: calc(100vh - 235px);
}
td[data-v-35b36a73]{
    border-right: none;
    border-left: none;
    font-size: 18px;
    /* vertical-align: top; */
    padding: 13px 5px;
}
.document-item-info[data-v-35b36a73] {
    display: flex;
    gap: 10px;
    align-items: center;
}
.document-item-info img[data-v-35b36a73] {
    position: relative;
    left: -9px;
    width: 30px;
    height: 30px;
}
.icon-name-container[data-v-35b36a73]{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}
.icon-name-container span[data-v-35b36a73]{
   font-size: 18px;
   font-family: Assistant;
   /* white-space: nowrap; */
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   margin: 0;
}
.marked-row[data-v-35b36a73]{
    background-color:var(--main-undertone-clr) ;
}
