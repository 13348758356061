.canvas-panel {
    flex: 0 0 400px;
    min-width: 300px;
    max-width: 1200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 90vh; 
    position: relative;
    animation: coolIn 0.7s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
    transform-origin: center center;
    background-color: var(--lightest-grey-clr);
}

.canvas-panel.closing {
    transition: none;
    animation: fadeShrink 0.3s forwards;
}

@keyframes coolIn {
    0% {
        transform: translateX(40px) scale(0.96);
        opacity: 0;
    }
    100% {
        transform: translateX(0) scale(1);
        opacity: 1;
    }
}

@keyframes fadeShrink {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0);
    }
}

.canvas-panel.main-collapsed {
    flex: 1 1;
    max-width: none;
}

.canvas-panel.resizing {
    -webkit-user-select: none;
            user-select: none;
}

.canvas-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.canvas-content {
    flex: 1 1;
    overflow: auto;
    padding: 24px;
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #f1f1f1;
}

.canvas-content::-webkit-scrollbar {
    width: 8px;
}

.canvas-content::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.canvas-content::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;
}

.resize-handle {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 6px;
    cursor: ew-resize;
    transition: background-color 0.2s, border-color 0.2s;
}

.resize-handle:hover,
.resize-handle.resizing {
    background-color: #bbb;
}