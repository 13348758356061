.custom-text-editor {
    overflow-y: auto;
    padding: 8px 0;
    outline: none;
    white-space: pre-wrap;
    font-size: 16px;
    font-family: 'Assistant', sans-serif;
    color: #131313;
    color: var(--text-clr, #131313);
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.6) transparent;
}

.custom-text-editor:empty:before {
    content: attr(placeholder);
    color: #828282;
    color: var(--grey-clr, #828282);
    pointer-events: none;
}

.custom-text-editor::-webkit-scrollbar {
    width: 6px;
}

.custom-text-editor::-webkit-scrollbar-track {
    background: transparent;
}

.custom-text-editor::-webkit-scrollbar-thumb {
    background-color: #d2d2d2;
    background-color: var(--light-grey-clr, #d2d2d2);
    border-radius: 10px;
}

.custom-text-editor:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

/* Styling for tokens/special elements inside the editor */
.editor-token {
    display: inline-block;
    color: #007bff;
    padding: 4px 8px;
    border-radius: 6px;
    font-weight: bold;
    margin-right: 4px;
}
