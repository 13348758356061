.modal-full-screen[data-v-787b8790] {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(44, 44, 44, 0.46);
    z-index: 999;
    font-family: Assistant, sans-serif; /* Apply font-family globally */
    -webkit-user-select: text !important;
            user-select: text !important;
}
.modal-container[data-v-787b8790] {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    position: fixed;
    justify-content: flex-start;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    background-color: white;
    border-radius: 16px;
    border: solid 1px #979797;
    width: calc(100vw - 430px);
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    z-index: 999;
    font-family: Assistant, sans-serif; /* Ensure consistency in modal */
}
.modal-close-btn[data-v-787b8790] {
    top: 30px;
    right: 30px;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 30px;
    position: absolute;
}
.header[data-v-787b8790] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    font-size: 40px;
    color: #260747;
    color: var(--dark-purple, #260747);
    gap: 10px;
    font-family: Inter; /* Font for the header */
}
.header-buttons[data-v-787b8790] {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-direction: row;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.header-buttons a[data-v-787b8790] {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    height: 24px;
}
.download-btn[data-v-787b8790] {
    color: #260747;
    color: var(--dark-purple, #260747);
}
header-buttons svg[data-v-787b8790] {
    width: 24px;
    height: 24px;
}
.modal-content[data-v-787b8790] {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    font-family: Assistant, sans-serif; /* Apply font globally for content */
}
.document-details[data-v-787b8790] {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
}
.details-info-headers[data-v-787b8790] {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: Assistant, sans-serif; /* Ensure headers have the same font */
}
.sub-header[data-v-787b8790] {
    font-size: 20px;
    font-weight: 600;
    color: #260747;
    color: var(--dark-purple, #260747);
    font-family: Assistant, sans-serif; /* Sub-header font */
}
.details-info-icons[data-v-787b8790] {
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
    text-align: right;
}
.details-part[data-v-787b8790] {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 40px;
}
.details-part svg[data-v-787b8790] {
    cursor: auto;
}
.details-sub-headers[data-v-787b8790] {
    text-align: right;
    width: 150px;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    gap: 5px;
    font-family: Assistant, sans-serif; /* Apply font to sub-headers */
}
.details-sub-headers span[data-v-787b8790] {
    font-size: 16px;
    font-weight: 600;
    text-align: right;
}
.document-prompts[data-v-787b8790] {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.document-prompts-titles[data-v-787b8790] {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    text-align: right;
    gap: 10px;
}
.document-prompts span[data-v-787b8790] {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    flex-direction: row-reverse;
}
.crm-button[data-v-787b8790] {
    border: solid 1px #9631ff;
    border: solid 1px var(--cellcom-purple, #9631ff);
    border-radius: 6px;
    cursor: pointer;
    width: 117px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}
  /* Style for the tasks list */
.flow-status-list[data-v-787b8790] {
    list-style-type: none;
    padding-left: 0;
    margin-top: 8px;
}
  
  /* Style for each flow item */
.flow-status-item[data-v-787b8790] {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}
  
  
  
  /* Style for the status icons */
.status-icon[data-v-787b8790] {
    width: 20px;
    height: 20px;
    margin-left: 8px;
}
  
  /* If using image files, you can control size via width and height */
.completed-icon img[data-v-787b8790],
  .not-completed-icon img[data-v-787b8790] {
    width: 20px;
    height: 20px;
}