.canvas-header {
    padding: 8px 16px;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa;
}

.canvas-header h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
}

.canvas-header .left-controls {
    display: flex;
    align-items: center;
    gap: 12px;
}

.canvas-header .right-controls {
    display: flex;
    align-items: center;
    gap: 12px;
}

.canvas-header svg {
    width: 20px;
    height: 20px;
}

.canvas-header .icon-button {
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 4px;
    color: #555;
    transition: background-color 0.2s;
}

.canvas-header .icon-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.canvas-header .icon-button:active {
    background-color: rgba(0, 0, 0, 0.1);
}

