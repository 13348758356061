.custom-select-selected[data-v-b9f29e7a] {
  cursor: pointer;
  gap: 1px;
  border: 0;
  border-radius: 8px;
  background: var(--lighter-grey-clr);
  padding: 4px 12px;
  height: 31px;
  font-size: 16px;
  width: 170px;
}
.custom-select-options[data-v-b9f29e7a] {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  background-color: var(--white-clr);
  z-index: 1000;
  font-size: 16px;
}

/* .custom-select-option {
  padding: 10px;
  cursor: pointer;
  background-color: var(--lighter-grey-2-clr);
} */
.sort-by-select[data-v-b9f29e7a] {
  display: flex;
  gap: 5px;
}
