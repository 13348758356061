.chat-conversation {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
}

.chat-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin-bottom: 5px;
    width: 100%;
}

.chat-header-content {
    display: flex;
    justify-content: flex-end; 
    align-items: center;
    width: 100%;
}

.messages-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
}

.message-container {
    display: flex;
    align-items: flex-start;
    max-width: 80%;
}

.avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.avatar svg {
    width: 20px;
    height: 20px;
    color: white;
}

.message-bubble {
    padding: 12px 16px;
    border-radius: 18px;
    max-width: 100%;
    word-wrap: break-word;
}

.message-text {
    margin: 0;
    line-height: 1.5;
    font-size: 16px;
}

.message-spacer {
    height: 10px;
    width: 100%;
}

.agent-dropdown {
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 14px;
    border: 1px solid #ccc;
}

.dropdown-container {
    margin-right: auto;
}

@media only screen and (max-width: 600px) {
    .message-spacer {
        height: 10px;
    }
}
