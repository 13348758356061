img[data-v-39489670] {
    width: 18px;
    height: 18px;
}
.flex-files[data-v-39489670] {
    display: flex;
    max-width: 1000px;
    gap: 5px;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.file[data-v-39489670] {
    background-color: var(--lighter-grey-clr);
    border-radius: 10px;
    padding: 0.5em 1em;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.icon-name-container[data-v-39489670] {
    display: flex;
    gap: 8px;
    font-family: Assistant;
    align-items: center;
    font-size: 18px;
}
button[data-v-39489670] {
    background-color: transparent;
    outline: none;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-btns-actions[data-v-39489670] {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
.flex-btns-actions button[data-v-39489670] {
    cursor: pointer;
    padding: 8px 16px;
    font-size: 16px;
}
  