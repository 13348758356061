.voice-main-container[data-v-40369383] {
    /* margin-top: 20px; */
    display: flex;
    flex-direction: column;
  
    -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.tool-kit[data-v-40369383] {
    background-color: var(--white-clr);
    position: relative;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    width: 315px;
    transition: all 0.2s ease-in;
}
.page-subheader[data-v-40369383] {
    font-size: 14px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    color: var(--text-clr);
}
.page-subheader button[data-v-40369383] {
    background: transparent;
    border: none;
    cursor: pointer;
    /* color: var(--text-clr); */
}
.breadcrumbs[data-v-40369383] {
    opacity: 0.5;
    margin-left: 5px;
    margin-bottom:3px;
}
.left-breadcrumbs[data-v-40369383] {
    display: flex;
    align-items: center;
    gap: 5px;
}
.back-button[data-v-40369383] {
    width: 30px;
}
.breadcrumbs-btn[data-v-40369383] {
    outline: 0;
    border: 0;
    background-color: transparent;
    padding: 0;
}
.breadcrumbs-btn[data-v-40369383]:hover {
    text-decoration: underline;
    cursor: pointer;
}
.flex-folders-files[data-v-40369383] {
    display: flex;
    flex-direction: column;
}
.folders-container[data-v-40369383] {
    padding-left: 15px;
    /* padding-top: 5px; */
    padding-right: 60px;
    padding-bottom: 38px;
}
.close-comment-icon[data-v-40369383] {
    padding: 0;
    cursor: pointer;
    /* margin-right: 25px; */
    /* display; */
    background-color: transparent;
    border: none;
}
.bordered-icons[data-v-40369383] {
    border: 1px solid var(--text-clr);
    padding: 0.2em 0.5em;
    border-radius: 50%;
}
.search-container[data-v-40369383] {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 14px;
}