body[data-v-d101ad07] {
  overflow-y: scroll;
}
tbody tr[data-v-d101ad07] {
  background-color: var(--white-clr);
  border: 10px solid var(--chat-background-clr);
}
.checkbox[data-v-d101ad07] {
  display: none;
}
tr[data-v-d101ad07] {
  width: 100%;
}
thead[data-v-d101ad07] {
  width: 100px;
}
.vector-modal-btn[data-v-d101ad07] {
  background-color: transparent;
  border: 0;
  outline: 0;
}
.flex-vector-methods[data-v-d101ad07] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
}
.flex-vector-methods button[data-v-d101ad07] {
  font-size: 18px;
  display: flex;
  gap: 16px;
}
thead th[data-v-d101ad07] {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* z-index: 2; */
  background-color: var(--chat-background-clr);
  padding: 13px 10px;
}
tr img[data-v-d101ad07] {
  width: 18px;
  height: 18px;
}
td[data-v-d101ad07],
th[data-v-d101ad07] {
  min-width: 120px;
}
table[data-v-d101ad07] {
  border: 0;
  width: 100%;
  table-layout: fixed;
  font-size: 16px;
  border-collapse: collapse;
  font-family: Assistant;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.table-container[data-v-d101ad07] {
  overflow: auto;
  max-height: calc(100vh - 220px);
}
td[data-v-d101ad07] {
  border-right: none;
  border-left: none;
  font-size: 18px;
  vertical-align: top;
  padding: 13px 5px;
}
.icon-name-container[data-v-d101ad07] {
  display: flex;
  gap: 8px;
  font-family: Assistant;

  align-items: center;
  font-size: 18px;
}
.marked-row[data-v-d101ad07] {
  background-color: var(--main-undertone-clr);
}