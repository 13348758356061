.agent-welcome-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.greeting-section {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.greeting-title {
    font-size: 24px;
    font-weight: bolder;
    color: #131313;
    color: var(--text-clr, #131313);
    margin-bottom: 8px;
}

.greeting-subtitle {
    font-size: 16px;
    margin: 0;
    font-weight: 500;
}

.controls-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 40px;
}

.view-options {
    display: flex;
    align-items: center;
    background-color: var(--lighter-grey-2-clr);
    border-radius: 10px;
    padding: 4px 8px;
}

.option {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    font-size: 14px;
    border: none;
    background: none;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.2s ease;
}

.option.selected {
    background-color: #ffffff;
    background-color: var(--white-clr, #ffffff);
}

.option-icon {
    width: 18px;
    height: 18px;
}

.add-agent-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    transition: color 0.2s;
    margin-left: auto;
}
.add-agent-content{
    font-weight: bold;
    font-size: large;
}

.add-icon {
    width: 22px;
    height: 18px;
}

.agents-carousel-wrapper {
    position: relative;
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
}

.agents-container {
    display: flex;
    gap: 20px;
    overflow-x: hidden;
    scroll-behavior: smooth;
    padding: 35px 40px;
    margin: 0 auto;
}

.agent-card-wrapper {
    flex: 0 0 auto;
    width: calc(25% - 15px);
    position: relative;
}

.carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ffffff;
    background-color: var(--white-clr, #ffffff);
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.carousel-arrow:hover {
    background-color: #f3f3f3;
    background-color: var(--lighest-grey-clr, #f3f3f3);
}

.carousel-arrow.left {
    left: 5px;
}

.carousel-arrow.right {
    right: 5px;
}

.carousel-arrow svg {
    width: 20px;
    height: 20px;
}

@media (max-width: 1100px) {
    .agent-card-wrapper {
        width: calc(33.333% - 14px); /* 3 cards with gap adjustment */
    }
}

@media (max-width: 850px) {
    .agent-card-wrapper {
        width: calc(50% - 10px);
    }

    .option {
        padding: 6px 12px;
    }
}

@media (max-width: 600px) {
    .controls-container {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }

    .view-options {
        width: 100%;
        justify-content: space-between;
    }

    .option {
        padding: 6px 8px;
    }

    .option span {
        display: none;
    }

    .add-agent-button {
        margin-left: 0;
        align-self: flex-end;
    }
}
