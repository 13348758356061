.documents-main-container[data-v-c670ef7c] {
  /* margin-top: 20px; */
  display: flex;
  flex-direction: column;

  -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.tool-kit[data-v-c670ef7c] {
  background-color: var(--white-clr);
  position: relative;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  width: 315px;
  transition: all 0.2s ease-in;
}
.page-subheader[data-v-c670ef7c] {
  font-size: 14px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  color: var(--text-clr);
}
.page-subheader button[data-v-c670ef7c] {
  background: transparent;
  border: none;
  cursor: pointer;
  /* color: var(--text-clr); */
}
.breadcrumbs[data-v-c670ef7c] {
  opacity: 0.5;
  margin-left: 5px;
  margin-bottom:3px;
}
.left-breadcrumbs[data-v-c670ef7c] {
  display: flex;
  align-items: center;
  gap: 5px;
}
.back-button[data-v-c670ef7c] {
  width: 30px;
}
.breadcrumbs-btn[data-v-c670ef7c] {
  outline: 0;
  border: 0;
  background-color: transparent;
  padding: 0;
}
.breadcrumbs-btn[data-v-c670ef7c]:hover {
  text-decoration: underline;
  cursor: pointer;
}
.flex-folders-files[data-v-c670ef7c] {
  display: flex;
  flex-direction: column;
}
.folders-container[data-v-c670ef7c] {
  padding-left: 15px;
  /* padding-top: 5px; */
  padding-right: 60px;
  padding-bottom: 38px;
}
.close-comment-icon[data-v-c670ef7c] {
  padding: 0;
  cursor: pointer;
  /* margin-right: 25px; */
  /* display; */
  background-color: transparent;
  border: none;
}
.bordered-icons[data-v-c670ef7c] {
  border: 1px solid var(--text-clr);
  padding: 0.2em 0.5em;
  border-radius: 50%;
}
.search-container[data-v-c670ef7c] {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 14px;
}