.temperature-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 160px;
}

.temp-button {
    background: none;
    border: none;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.temp-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.temperature-value-container {
    background-color: var(--lighter-grey-clr);
    border-radius: 6px;
    padding: 5px 16px;
    margin: 0 8px;
    min-width: 45px;
    text-align: center;
}

.temperature-value {
    font-size: 16px;
    font-weight: 500;
}
