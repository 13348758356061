.support-card {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: var(--white-clr);
    border-radius: 12px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
    width: 256px;
    height: 160px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
}

.support-card:hover {
    transform: scale(1.03);
    box-shadow: 0 15px 20px -3px rgba(0, 0, 0, 0.12),
        0 8px 12px -2px rgba(0, 0, 0, 0.07);
}

.icon-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;
}

.left-icons {
    display: flex;
}

.right-icons {
    display: flex;
}

.icon {
    border-radius: 50%;
    padding: 4px;
    margin-right: 8px;
    z-index: 10;
    color: var(--grey-clr);
}

.right-icons .icon {
    margin-right: 0;
}

.icon.clickable {
    cursor: pointer;
    transition: transform 0.2s ease;
}

.icon.clickable:hover {
    transform: scale(1.1);
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    gap: 4px;
    color: #2abcc3;
    color: var(--main-clr, #2abcc3);
    margin-top: -35px;
}

.title {
    font-size: 18px;
    font-weight: 500;
    color: var(--pink-clr);
    margin-top: 8px;
    margin-bottom: 0;
    text-align: center;
}

.subtitle {
    font-size: 14px;
    color: var(--dark-grey-clr);
    margin-top: 4px;
    margin-bottom: 0;
    text-align: center;
}