/* Common styles for all settings components */
.settings-item {
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    align-items: center;
    width: 100%;
}

.settings-item:last-child {
    border-bottom: none;
}

.settings-item-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
}

.settings-icon {
    margin-right: 8px;
    width: 18px;
    height: 18px;
    color: #595959;
    color: var(--dark-grey-clr, #595959);
}

.settings-item label {
    font-weight: 500;
    color: #131313;
    color: var(--text-clr, #131313);
    font-size: 15px;
}