.user-message {
    margin-left: auto;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    white-space: pre-wrap;
}

.user-avatar {
    margin-left: 12px;
}

.user-bubble {
    background-color: var(--lighter-grey-clr);
    color: white;
    border-top-right-radius: 4px;
}
