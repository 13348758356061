.tooltip-container[data-v-65f8b3d5] {
  display: 'inline-block';
  position: 'relative';
  width: 100%;
  /* overflow: hidden;
  text-overflow: ellipsis; */
}
.tooltip[data-v-65f8b3d5] {
  background-color: var(--white-clr);
  color: var(--text-clr);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  z-index: 1000;
  pointer-events: none;
  opacity: 0;
  position: absolute;
}
.tooltip-container:hover .tooltip[data-v-65f8b3d5] {
  opacity: 0.9;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
}
