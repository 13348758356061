.dropdown-with-search {
    width: 160px;
}

.dropdown-with-search .MuiOutlinedInput-root {
    height: 30px;
    padding: 0 8px;
    background-color: var(--lighter-grey-clr);
    border-radius: 8px;
    font-size: 16px;
    color: var(--text-clr);
}

.dropdown-with-search .MuiOutlinedInput-root fieldset {
    border: none;
}

.dropdown-with-search .MuiAutocomplete-input {
    padding: 0 !important;
    font-size: 16px;
    font-family: Assistant;
}

.dropdown-with-search .MuiAutocomplete-endAdornment {
    top: 50%;
    transform: translateY(-50%);
}

.dropdown-with-search .MuiAutocomplete-endAdornment .MuiButtonBase-root {
    padding: 2px;
}

.MuiAutocomplete-popper {
    min-width: 150px !important;
    width: auto !important;
}

.MuiPaper-root {
    animation: dropdownOpen 0.2s ease-out forwards;
}

@keyframes dropdownOpen {
    from {
        opacity: 0;
        transform: translateY(-8px) scale(0.95);
    }
    to {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}