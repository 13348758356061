.close-button {
    background: none;
    background-color: white;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.close-icon {
    display: flex;
    color: #828282;
    color: var(--grey-clr, #828282);
}

.close-icon svg {
    width: 20px;
    height: 20px;
}
