.settings-panel {
    padding: 0;
    background-color: white;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.settings-panel .settings-item {
    max-width: 300px;
    width: 100%;
}