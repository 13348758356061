.active-try g path[data-v-948635dc] {
    fill: var(--red-clr);
}
.close-comment-icon[data-v-948635dc] {
    padding: 0;
    background-color: transparent;
    border: none;
}
.directions-btns-flex[data-v-948635dc] {
    display: flex;
    margin-right: 25px;
}
.input-role[data-v-948635dc] {
    font-family: Assistant;
    font-size: 16px;
    font-style: normal;
    min-width: 100px;
    font-weight: 600;
    border-radius: 10px;
    min-height: 51px;
    cursor: pointer;
    line-height: 20px;
    padding: 10px 30px;
    display: block;
    border: none;
    color: var(--secondary-clr);
    background-color: var(--main-undertone-clr);
}
.lower-part[data-v-948635dc] {
    display: flex;
    justify-content: flex-end;
    height: 45px;
}
.role-btn-container[data-v-948635dc] {
    flex-grow: 1;
    max-width: 100px;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
}
.comment-container[data-v-948635dc] {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    background-color: var(--white-clr);
    flex-direction: column;
    display: flex;
}
.loading-ans-container[data-v-948635dc] {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    background-color: var(--white-clr);
    flex-direction: row;
    display: flex;
}
.question-input-form[data-v-948635dc] {
    flex-grow: 1;
    flex-direction: column;
    display: flex;
    justify-content: center;
}
.question-input-form .input-container[data-v-948635dc] {
    position: relative;
    flex-grow: 1;
    width: 100%;
    display: flex;
    direction: rtl;
    border-radius: 10px;
}
.question-input-form .mic-logo[data-v-948635dc] {
    width: 24px;
    left: 10px;
    top: 7px;
    position: absolute;
    z-index: 100;
}
.question-input-form .mic-logo-desktop[data-v-948635dc] {
    width: 24px;
    left: 10px;
    position: absolute;
    z-index: 100;
    top: 10px;
}
.question-input[data-v-948635dc] {
    resize: none;
    min-height: 48px;
    width: 100%;
    padding: 15px 10px 0px 10px;
    position: relative;
    font-family: Assistant;
    border-radius: 10px;
    font-size: 16px;
    color: var(--text-clr);
    background-color: transparent;
    border: 0;
    overflow: hidden;
}
.question-input-form .focus-bg[data-v-948635dc] {
    background: var(--lighter-grey-clr);
    outline-width: 0;
    color: var(--text-clr);
}
.question-input-form .question-input[data-v-948635dc]::placeholder {
    color: grey;
    font-size: 14px;
    font-family: Assistant;
}
.question-input-form .question-input[data-v-948635dc]:focus:not(.focus-visible) {
    outline: none;
}
.resources-container[data-v-948635dc] {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 0 100px;
}
.single-resource-link[data-v-948635dc] {
    cursor: pointer;
}
.single-resource-link svg[data-v-948635dc] {
    position: relative;
    top: 5px;
}
.question-input-form .question-input-iphone[data-v-948635dc] {
    direction: rtl;
    border: 0;
    height: 100%;
    width: 85%;
    right: -20px;
    z-index: 10000;
    position: relative;
    top: 6px;
    font-size: 18px;
    background-color: transparent;
    font-family: 'openSans-regular';
}
.question-input-form .question-input-iphone[data-v-948635dc]::placeholder {
    color: var(--dark-grey-clr);
    font-size: 18px;
    font-family: 'openSans-regular';
}
.question-input-form .question-input-iphone[data-v-948635dc]:focus {
    outline-width: 0;
}
.question-input-form .question-input-iphone[data-v-948635dc]:focus:not(.focus-visible) {
    outline: none;
}
.question-input-form .question-input-img[data-v-948635dc] {
    position: absolute;
    width: initial;
    cursor: inherit;
    right: 0;
    height: 46px;
    top: -3px;
    width: 560px;
    margin-bottom: 10px;
}
.container[data-v-948635dc] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.box-minmax[data-v-948635dc] {
    margin-top: 15px;
    width: 225px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: var(--faded-blue-clr);
}
.box-minmax span[data-v-948635dc]:first-child {
    margin-left: 5px;
}
.pop-up-btn-container-mobile[data-v-948635dc] {
    display: none;
}
.pop-up-btn-container button[data-v-948635dc] {
    border-radius: 5px;
    background-color: var(--grey-clr);
    border: 0;
    width: 85px;
    font-size: 16px;
    cursor: pointer;
    color: var(--white-clr);
}
.marginTop40[data-v-948635dc] {
    margin-top: 40px !important;
}
.marginTop30[data-v-948635dc] {
    margin-top: 30px !important;
}
.rs-range[data-v-948635dc] {
    margin-top: 22px;
    width: 200px;
    -webkit-appearance: none;
}
.rs-range[data-v-948635dc]:focus {
    outline: none;
}
.rs-range[data-v-948635dc]::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    background: var(--black-clr);
    border-radius: 0px;
    border: 0px solid #010101;
}
.rs-range[data-v-948635dc]::-moz-range-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    background: var(--black-clr);
    border-radius: 0px;
    border: 0px solid #010101;
}
.rs-range[data-v-948635dc]::-webkit-slider-thumb {
    box-shadow: none;
    border: 0px solid black;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
    height: 32px;
    width: 22px;
    border-radius: 22px;
    background: var(--black-clr);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -15px;
}
.rs-range[data-v-948635dc]::-moz-range-thumb {
    box-shadow: none;
    border: 0px solid black;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
    height: 32px;
    width: 22px;
    border-radius: 22px;
    background: var(--black-clr);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -15px;
}
.rs-range[data-v-948635dc]::-moz-focus-outer {
    border: 0;
}
.rs-label[data-v-948635dc] {
    position: relative;
    transform-origin: center center;
    display: block;
    width: 22px;
    height: 22px;
    background: transparent;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    box-sizing: border-box;
    border: 1px solid;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: var(--faded-blue-clr);
}
.rs-label[data-v-948635dc]::after {
    display: block;
    font-size: 20px;
    letter-spacing: 0.07em;
    margin-top: -2px;
}
@media only screen and (max-width: 600px) {
.role-btn-container[data-v-948635dc] {
        flex-grow: 0;
}
.input-role[data-v-948635dc] {
        min-width: 55px;
        padding: 10px 15px;
}
.right-flex[data-v-948635dc] {
        display: none !important;
}
.submit-btn-mobile[data-v-948635dc] {
        background-color: transparent;
        outline: 0;
        border: 0;
}
}
.flex-upper-part[data-v-948635dc] {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}
.settings[data-v-948635dc] {
    display: flex;
    max-height: 60px;
}
.right-flex[data-v-948635dc] {
    display: flex;
    width: 98px;
    justify-content: flex-end;
}
.submit-btn[data-v-948635dc] {
    border-radius: 8px;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    z-index: 1;
    line-height: 20px;
    border: none;
    font-family: Assistant;
    background-color: var(--main-clr);
    padding: 6px 12px 6px 12px;
    justify-content: center;
    align-items: center;
}
.submit-btn-strip[data-v-948635dc] {
    display: flex;
    padding-bottom: 8px;
    padding-right: 8px;
    padding-left: 8px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    align-items: flex-end;
    justify-content: flex-end;
}
.images-container[data-v-948635dc] {
    display: flex;
    gap: 20px;
    max-width: 100%;
    flex-wrap: wrap;
}
.image-container[data-v-948635dc] {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 14px 47px 10px 28px;
    background: #e3e3e3;
    position: relative;
}
.download-btn[data-v-948635dc] {
    position: absolute;
    right: 7px;
    width: 28px;
    height: 28px;
}
.image-input[data-v-948635dc] {
    resize: none;
    /* min-height: 48px; */
    width: 100%;
    /* min-height: 100%; */
    padding: 15px 10px 0px 10px;
    /* position: relative; */
    font-family: Assistant;
    border-radius: 10px;
    font-size: 16px;
    color: var(--text-clr);
    background-color: transparent;
    border: 0;
}
