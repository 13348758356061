.password-container span[data-v-f571f9b9] {
  position: absolute;
  right: 10px; 
  top: 50%;
  transform: translateY(-50%); 
  cursor: pointer;
  display: flex;
  align-items: center; 
  justify-content: center;
  height: 100%;
}
.password-input[data-v-f571f9b9] {
  background: var(--lighter-grey-clr);
  border: 0;
  font-size: 16px;
  padding: 8px 12px;
  width: 133px;
  border-radius: 8px;
  height: 30px;
  outline: none;
}
.password-input[data-v-f571f9b9]::placeholder {
  color: var(--black-clr);
}
