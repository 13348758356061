.submit-strip[data-v-058b2f79] {
    align-items: center;
    position: absolute;
    bottom: 0%;
    padding-left: 20px;
    width: 100%;
    gap: 10px;
    height: 80px;
    background-color: var(--lighter-grey-clr);
    display: flex;
}
.submit-btn[data-v-058b2f79] {
    border-radius: 8px;
    display: flex;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border: none;
    font-family: Assistant;
    background-color: var(--main-clr);
    width: 107px;
    height: 32px;
    padding: 6px 22px 6px 23px;
    justify-content: center;
    align-items: center;
}
.log-details[data-v-058b2f79]{
    display: flex;
    gap: 5px;
    flex-direction: column;
}
.log-date[data-v-058b2f79]{
    font-weight: 600;
    font-size: 16px;
}