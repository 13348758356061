.chat-bubble-container {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    padding: 10px 20px;
    background-color: #f3f3f3;
    background-color: var(--lighter-grey-2-clr, #f3f3f3);
    border-radius: 20px;
    position: relative;
    z-index: 100;
    max-height: 40vh;
    overflow-y: auto;
    box-sizing: border-box;
}

.input-wrapper {
    width: 100%;
    padding-bottom: 8px;
}

.controls-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.input-actions {
    display: flex;
    align-items: center;
    gap: 12px;
}

.action-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #595959;
    color: var(--dark-grey-clr, #595959);
}

.action-button:hover {
    color: #303030;
    color: var(--darker-grey-clr, #303030);
}

.send-button {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 50%;
    background-color: var(--main-clr);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    color: var(--white-clr, #ffffff);
    cursor: pointer;
    transition: background-color 0.2s;
}