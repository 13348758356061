.tool-container {
    position: relative;
}

.tool-dropup {
    position: absolute;
    bottom: 36px; /* Opens upward */
    right: -80px; /* Adjust as needed */
    background: #fff;
    background: var(--white-clr, #fff);
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 8px 12px; /* Increased horizontal padding */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    z-index: 100000;
    display: flex;
    align-items: center;
    gap: 8px;
}

.tool-dropup .dropup-option {
    display: flex;
    align-items: center;
    gap: 4px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px 8px;
    font-size: 16px;
    color: inherit;
    position: relative;
}

.tool-dropup .dropup-option:hover::before {
    content: '';
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    background: var(--lighter-grey-2-clr);
    border-radius: 10px;
    z-index: -1;
}

.tool-dropup svg {
    width: 24px;
    height: 24px;
}

