.assistant-avatar {
    margin-right: -6px;
    margin-top: 4px;
}

.assistant-bubble {
    border-top-left-radius: 4px;
}

/* Markdown styles for the assistant bubble */
.assistant-bubble .message-text {
    width: 100%;
}

/* Style for markdown code blocks */
.assistant-bubble pre {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 10px;
    overflow-x: auto;
    font-family: monospace;
    margin: 10px 0;
}

/* Style for inline code */
.assistant-bubble code {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    font-family: monospace;
}

/* Style for headings */
.assistant-bubble h1,
.assistant-bubble h2,
.assistant-bubble h3,
.assistant-bubble h4,
.assistant-bubble h5,
.assistant-bubble h6 {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 600;
}

/* Style for lists */
.assistant-bubble ul,
.assistant-bubble ol {
    padding-left: 20px;
    margin: 10px 0;
}

/* Style for links */
.assistant-bubble a {
    color: #0077cc;
    text-decoration: none;
}

.assistant-bubble a:hover {
    text-decoration: underline;
}

/* Style for blockquotes */
.assistant-bubble blockquote {
    border-left: 3px solid #ddd;
    padding-left: 10px;
    margin-left: 0;
    color: #666;
}

/* Style for tables */
.assistant-bubble table {
    border-collapse: collapse;
    width: 100%;
    margin: 15px 0;
}

.assistant-bubble th,
.assistant-bubble td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.assistant-bubble th {
    background-color: rgba(0, 0, 0, 0.05);
}

.katex-display {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 5px 0;
}

.katex {
    font-size: 1.2em;
}

/* Improve code block display */
.assistant-bubble pre > div {
    margin: 0 !important;
    border-radius: 4px !important;
}
